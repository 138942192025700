// General
let checkedAvailability = false;
let pinspotsRemove, confettiRemove, uplightRemove, marqueeRemove = false;
const monthNames = [
	'January', 'February', 'March', 'April', 'May', 'June',
	'July', 'August', 'September', 'October', 'November', 'December'
];
const abrevMonthNames = [
	'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
	'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

const addWorkDays = (startDate, days) => {
	const dow = startDate.getDay();
	let daysToAdd = parseInt(days);
	// If the current day is Sunday add one day
	if (dow == 0) daysToAdd++;
	// If the start date plus the additional days falls on or after the closest Saturday calculate weekends
	if (dow + daysToAdd >= 6) {
		//Subtract days in current working week from work days
		let remainingWorkDays = daysToAdd - (5 - dow);
		//Add current working week's weekend
		daysToAdd += 2;
		if (remainingWorkDays > 5) {
			//Add two days for each working week by calculating how many weeks are included
			daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
			//Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
			if (remainingWorkDays % 5 == 0) daysToAdd -= 2;
		}
	}
	startDate.setDate(startDate.getDate() + daysToAdd);
	return startDate;
};

const availabilityAlert = () => {
	const date = $('.date-picker').val().split('/');
	const newDate = `${monthNames[parseInt(date[0]) - 1]} ${date[1]}, ${date[2]}`;
	const lightType = typeof confettiProducts !== 'undefined' ? 'confetti launcher' : 'lights';
	if (checkedAvailability) {
		$('.diy-check-availability').removeClass('d-none');
		$('.diy-check-availability').find('span').text(`Yes! We do have ${$('.diy-qty-input').val()} ${lightType} available to rent for ${newDate}.`);
	}
	const checkoutEventDate = $('.checkout-event-date');
	if (checkoutEventDate.length) {
		checkoutEventDate.text(newDate);
		const eventDate = new Date($('.date-picker').val());
		const dueDate = addWorkDays(new Date($('.date-picker').val()), 2);
		const arrivalDate = new Date($('.date-picker').val());
		arrivalDate.setDate(arrivalDate.getDate() - 2);
		$('.checkout-arrival-date').text(`${abrevMonthNames[arrivalDate.getMonth()]} ${arrivalDate.getDate()}, ${arrivalDate.getFullYear()}`);
		$('.checkout-due-date').text(`${abrevMonthNames[dueDate.getMonth()]} ${dueDate.getDate()}, ${dueDate.getFullYear()}`);
		$('.checkout-arrival-date-2').text(fetchDate(arrivalDate));
		$('.checkout-due-date-2').text(fetchDate(dueDate));
		$('.checkout-event-date-2').text(fetchDate(eventDate));
	};
};

const fetchDate = (date) => {
	const day = date.getDate() < 10 ? `0${date.getDate()}` : String(date.getDate());
	const motnh = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : String(date.getMonth() + 1);
	return `${motnh}/${day}/${date.getFullYear()}`;
}

const handleAvailabilityCheck = () => {
	if ($('.diy-rent-form')[0].reportValidity()) {
		const cutDate = new Date();
		cutDate.setDate(cutDate.getDate() + 2);
		cutDate.setHours(23, 59, 59, 999);
		const verificationDate = new Date();
		verificationDate.setDate(verificationDate.getDate() + 8);
		verificationDate.setHours(23, 59, 59, 999);
		const date = new Date($('.date-picker').val());
		if (cutDate >= date) {
			$('.diy-availability-alert').removeClass('d-none');
			$('.diy-check-availability-btn').text('Check Availability');
			$('.diy-check-availability-btn').blur();
			$('.diy-check-availability').addClass('d-none');
			checkedAvailability = false;
		} else if (date < verificationDate) {
			submitDiyForm();
		} else {
			$('.diy-availability-alert').addClass('d-none');
			$('.diy-check-availability-btn').text('Rent Now');
			$('.diy-check-availability-btn').blur();
			if (checkedAvailability) submitDiyForm();
			else checkedAvailability = true;
			availabilityAlert();
			$('.alert-danger').addClass('d-none');
		}
	}
};

const submitDiyForm = () => $('.diy-rent-form').submit();

const fetchValue = (item) => {
	if (item.length) return parseInt(item.text().replace(/[\$,]+/gi, '')) || 0
	return 0;
};

const currencyFormatter = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 2
});

const updateLateFee = () => {
	const uplightQty = uplightRemove || !$('.diy-uplight-qty-input').length ? 0 : $('.diy-uplight-qty-input').val();
	const pinspotsQty = pinspotsRemove || !$('.diy-pinspots-qty-input').length ? 0 : $('.diy-pinspots-qty-input').val();
	const confettiQty = confettiRemove || !$('.diy-confettis-qty-input').length ? 0 : $('.diy-confettis-qty-input').val();
	const currentConfetti = confettiProducts.find((p) => p.id === parseInt($('.diy-confettis-product-input').val()));
	const marqueeQty = marqueeRemove ? 0 : (fetchValue($('.diy-marquees-total')) / marqueeCost);
	const lateFee = (pinspotsLate * pinspotsQty) + (uplightQty * defineProduct().late) + (confettiQty * (currentConfetti ? currentConfetti.late : 0)) + (marqueeQty * products[0].late);
	$('.diy-late-fee').text(`$${lateFee}`);
}

const updateCheckoutTotals = () => {
	const uplightQty = uplightRemove ? 0 : $('.diy-uplight-qty-input').val() || 0;
	const pinspotsQty = pinspotsRemove ? 0 : $('.diy-pinspots-qty-input').val() || 0;
	const currentConfetti = typeof confettiProducts !== 'undefined' && confettiProducts.find((p) => p.id === parseInt(confettiMapping[$('.diy-confettis-product-input').val()]));
	const confettiQty = confettiRemove ? 0 : $('.diy-confettis-qty-input').val() || 0;

	let terms = '';
	let uplight = 'Slim Par 56'
	const light = $('.diy-light-upgrade-input').is(':checked');
	const wireless = $('.diy-wireless-upgrade-input').is(':checked');
	if (wireless && light) uplight = 'Wireless Par 64 RGBA';
	else if (wireless && !light) uplight = 'Wireless EZPar 56';
	else if (!wireless && light) uplight = 'Slimpar 64 RGBA';

	// Uplight
	if (uplightQty) terms += `<p class="text-secondary font-size-xs mb-0">${uplightQty} - ${uplight}</p>`;

	// Pin Spots
	if (pinspotsQty) terms += `<p class="text-secondary font-size-xs mb-0">${pinspotsQty} - Pin Spot</p>`;

	// Confetti
	if (confettiQty) terms += `<p class="text-secondary font-size-xs mb-0">${confettiQty} - Confetti Launcher</p>`;
	if (currentConfetti && currentConfetti.id !== noConfettiProduct) {
		terms += `
			<p class="text-secondary font-size-xs mb-0">${$('.diy-confetti-upgrade-input').is(':checked') ? confettiQty * 2 : confettiQty} - ${currentConfetti.name.includes('Glow') ? 'Glow-in-the-Dark Confetti (1lb)' : 'Multi-Colored Confetti (1lb)'}</p>
		`
	}

	// Marquee Letters
	const marquees = $('.marquee-character-item').not('.d-none');
	marquees.each(function () {
		terms += `<p class="text-secondary font-size-xs mb-0">1 - ${this.getElementsByTagName('p')[0].innerText} (21" Marquee)</p>`;
	})
	if (marquees.length && $('.diy-marquee-upgrade-input').is(':checked')) terms += `<p class="text-secondary font-size-xs mb-0">${marquees.length} - Marquee Base</p>`;
	$('.terms-booked-items').html(terms)

	const uplightTotal = $('.diy-uplight-total');
	const lightTotal = $('.diy-light-upgrade-total');
	const wirelessTotal = $('.diy-wireless-upgrade-total');
	const marqueeUpgradeTotal = $('.diy-marquee-upgrade-total');
	const confettiUpgradeTotal = $('.diy-confetti-upgrade-total');
	const pinspotsTotal = $('.diy-pinspots-total');
	const confettiTotal = $('.diy-confetti-total');
	const marqueesTotal = $('.diy-marquees-total');
	const total = fetchValue(uplightTotal)
		+ fetchValue(lightTotal)
		+ fetchValue(wirelessTotal)
		+ fetchValue(marqueeUpgradeTotal)
		+ fetchValue(pinspotsTotal)
		+ fetchValue(confettiTotal)
		+ fetchValue(marqueesTotal)
		+ fetchValue(confettiUpgradeTotal);
	const percentualDeposit = Math.round(total * 0.25);
	const deposit = percentualDeposit > 25 ? percentualDeposit : 25;
	$('.diy-checkout-total').text(currencyFormatter.format(total));
	$('.diy-checkout-deposit').text(currencyFormatter.format(deposit));
	$('.diy-checkout-balance').text(currencyFormatter.format(total - deposit));
	if ($('.diy-checkout-total').length) {
		updateLateFee();
		$('.partial-order-update-input').trigger('blur');
	}
};

const removeElement = (item) => {
	const inputName = item.attr('name').replace('qty', '_destroy');
	item.after(`<input value="1" type="hidden" name="${inputName}">`);
};

// Uplight
const defineUpgrades = () => {
	if (typeof currentUplight !== 'undefined') {
		if (currentUplight === slim_64.id || currentUplight === wireless_64.id) {
			$('.diy-light-upgrade-input').prop('checked', true);
		}
		if (currentUplight === wireless_56.id || currentUplight === wireless_64.id) {
			$('.diy-wireless-upgrade-input').prop('checked', true);
		}
	}
};

const defineProduct = () => {
	const light = $('.diy-light-upgrade-input').is(':checked');
	const wireless = $('.diy-wireless-upgrade-input').is(':checked');
	if (wireless && light) return wireless_64;
	else if (wireless && !light) return wireless_56;
	else if (!wireless && light) return slim_64;
	else return slim_56;
};

const updateUplightSubtotal = () => {
	const light = $('.diy-light-upgrade-input').is(':checked');
	const wireless = $('.diy-wireless-upgrade-input').is(':checked');
	const lightTotal = $('.diy-light-upgrade-total');
	const wirelessTotal = $('.diy-wireless-upgrade-total');
	if (lightTotal.length && wirelessTotal.length) {
		const uplightQty = uplightRemove ? 0 : $('.diy-uplight-qty-input').val();
		lightTotal.text(currencyFormatter.format(light ? uplightQty * brigtherUpgradeCost : 0));
		wirelessTotal.text(currencyFormatter.format(wireless ? uplightQty * wirelessUpgradeCost : 0));
	}
};

const updateUplightTotal = () => {
	const uplightQty = uplightRemove ? 0 : $('.diy-uplight-qty-input').val();
	$('.diy-uplight-total').text(currencyFormatter.format(uplightCost * uplightQty));
	updateUplightSubtotal();
};

// Pinspots
const updatePinspotsTotal = () => {
	const uplightQty = pinspotsRemove ? 0 : $('.diy-pinspots-qty-input').val();
	$('.diy-pinspots-total').text(currencyFormatter.format(pinspotsCost * uplightQty));
};

// Confetti
const updateConfettiSubtotal = () => {
	const upgrade = $('.diy-confetti-upgrade-input').is(':checked') ? confettiUpgradeCost : 0;
	const confettiQty = confettiRemove ? 0 : $('.diy-confettis-qty-input').val();
	$('.diy-confetti-upgrade-total').text(currencyFormatter.format(confettiQty * upgrade));
};

const updateConfettiTotal = () => {
	const currentConfetti = confettiProducts.find((p) => p.id === parseInt(confettiMapping[$('.diy-confettis-product-input').val()]));
	if (currentConfetti.id === noConfettiProduct) {
		$('.diy-confetti-upgrade-block').addClass('d-none');
		$('.diy-check-availability-btn').addClass('mt-2');
		$('.diy-confetti-upgrade-input').prop('checked', false);
	} else {
		$('.diy-check-availability-btn').removeClass('mt-2');
		$('.diy-confetti-upgrade-block').removeClass('d-none');
	}
	const confettiQty = confettiRemove ? 0 : $('.diy-confettis-qty-input').val();
	$('.diy-confetti-price').text(currencyFormatter.format(currentConfetti.cost));
	$('.diy-confetti-total').text(currencyFormatter.format(currentConfetti.cost * confettiQty));
	updateConfettiSubtotal();
};

// Marquee
const updateMarqueeSubtotal = () => {
	const upgrade = $('.diy-marquee-upgrade-input').is(':checked') ? marqueeUpgradeCost : 0;
	const total = marqueeRemove ? 0 : fetchValue($('.diy-marquees-total'));
	$('.diy-marquee-upgrade-total').text(currencyFormatter.format((total / marqueeCost) * upgrade));
};

const updateMarqueeTotal = (num) => {
	const marqueeTotal = $('.diy-marquees-total');
	if (marqueeTotal.length) {
		const currentTotal = fetchValue(marqueeTotal);
		const newTotal = marqueeRemove ? 0 : currentTotal + (marqueeCost * num);
		marqueeTotal.text(currencyFormatter.format(newTotal));
		if (newTotal === 0) $('.marquee-element').addClass('d-none');
		updateMarqueeSubtotal();
		updateCheckoutTotals();
	}
};

function marqueeRemoval(e) {
	e.preventDefault();
	const item = $(this).parents('.marquee-character-item');
	if (isNewReservation) {
		item.remove();
	} else {
		const inputName = item.find('.marquee-character-qty-input').attr('name').replace('qty', '_destroy');
		item.append(`<input value="1" type="hidden" name="${inputName}">`);
		item.removeClass('d-flex');
		item.addClass('d-none');
	}
	updateMarqueeTotal(-1);
};

const marqueeItem = (product, count, identifier) => (
	`
		<div class="col-lg-2 col-2 mb-2 min-h-50 min-w-38 min-h-50-responsive min-w-38-responsive bg-light border align-items-center d-flex ml-2 mr-2 p-0 marquee-character-item">
			<input value="${product.id}" type="hidden" name="reservation[booked_items_attributes][${count}][product_id]" id="reservation_booked_items_attributes_0_product_id">
			<input value="1" type="hidden" name="reservation[booked_items_attributes][${count}][qty]" id="reservation_booked_items_attributes_0_qty" class="marquee-character-qty-input">
			<p class="text-secondary font-size-xs text-center m-auto marquee-character-${identifier}">
				${product.name}
			</p>
		</div>
	`
);

const marqueeRemovalBtn = () => (
	`
		<a class="marquee-letter-remove">
			<i class="diy-icon-minus align-middle card-delete-icon text-muted"></i>
		</a>
	`
);

const marqueeAddBtn = () => (
	`
		<div class="col-lg-2 col-3 mb-2 align-self-center marquee-character-add-btn">
			<i class="diy-icon-plus light-purple-color font-size-lg" data-target="#pickMarqueeLettersModal" data-toggle="modal"></i>
		</div>
	`
);

const validCharacters = (characters) => {
	const invalid = [];
	characters.forEach((character) => {
		const product = products.find((p) => p.name.toUpperCase() === character.toUpperCase());
		if (!product && !/\s/.test(character)) {
			invalid.push(character.toUpperCase());
		}
	});
	if (invalid.length === 0) return true;
	$('.marquee-characters-modal-alert').removeClass('d-none');
	$('.marquee-characters-modal-alert').html(`We do not have the following characters: ${invalid.join(', ')}`);
	return false;
};

function marqueeAddition(e) {
	e.preventDefault();
	$('.marquee-submit-btn').removeClass('btn-light')
	$('.marquee-submit-btn').addClass('btn-primary')
	$('.marquee-character-add-btn').remove();
	const bookedItemName = $('.marquee-character-qty-input').last().attr('name');
	let totalAdded = 0;
	let currentNum = bookedItemName ? parseInt(bookedItemName.replace('reservation[booked_items_attributes][', '').replace('][qty]', '')) + 1 : 0;
	const characters = $('.marquee-characters-input').val().split('');
	if (characters.length === 0) {
		$('.marquee-characters-modal-alert').removeClass('d-none');
		$('.marquee-characters-modal-alert').html('Please add at leaste 1 character.');
		return;
	} else if (validCharacters(characters)) {
		characters.forEach((character) => {
			const product = products.find((p) => p.name.toUpperCase() === character.toUpperCase());
			if (product) {
				const identifier = Date.now();
				$('.marquee-character-container').append(() => $(marqueeItem(product, currentNum, identifier)).click());
				$(`.marquee-character-${identifier}`).append(marqueeRemovalBtn()).on('click', marqueeRemoval);
				currentNum++;
				totalAdded++;
			}
		});
		$('.marquee-character-container').append(marqueeAddBtn());
		$('.marquee-characters-modal-element').addClass('d-none');
		$('.marquee-characters-label').removeClass('d-none');
		$('.marquee-characters').removeClass('d-none');
		$('.marquee-characters-modal').modal('toggle');
		$('.marquee-characters-input').val('');
		$('.marquee-characters-modal-alert').addClass('d-none');
		updateMarqueeTotal(totalAdded);
	}
};

$(function () {
	// General
	$('.product .owl-carousel').owlCarousel({
		loop: true,
		margin: 10,
		nav: false,
		items: 1,
		center: true,
		dotsData: true,
		dotsContainer: '.product-dot',
	});

	$('.product .owl-dot').on('click', function () {
		$('.product .owl-carousel').trigger('to.owl.carousel', [$(this).index(), 300]);
		$('.product .owl-dot').removeClass('active');
		$(this).addClass('active');
	});

	// General
	$('.report-validty-input').on('change', function (e) {
		this.reportValidity();
	});

	$('.report-custom-validty-input').on('change', function (e) {
		this.setCustomValidity('');
	});

	$('.diy-check-availability-btn').on('click', handleAvailabilityCheck);

	$('.diy-rent-btn').on('click', function (e) {
		e.preventDefault();
		submitDiyForm();
	});

	$('.diy-qty-input, .diy-event-date-input').on('change', function (e) {
		availabilityAlert();
	});

	$('.cut-date-form').on('submit', function (e) {
		e.preventDefault();
		const input = $(this).find('input[name="message[state]"]');
		if (/(^\d{5}$)|(^\d{5}-\d{4}$)/.test(input.val())) {
			this.submit();
		} else {
			input[0].setCustomValidity('Invalid Zip Code');
		}
	});

	// Uplighting
	defineUpgrades();
	updateUplightSubtotal();
	$('.diy-uplight-checkbox').on('change', () => {
		$('.diy-uplight-product-input').val(defineProduct().id);
		$('.diy-uplight-value').text(`$${defineProduct().value}`);
		updateUplightSubtotal();
		updateCheckoutTotals();
	});
	$('.diy-uplight-qty-input').on('change', () => {
		updateUplightTotal();
		updateCheckoutTotals();
	});
	$('.uplight-remove').on('click', () => {
		uplightRemove = true;
		updateUplightTotal();
		updateCheckoutTotals();
		$('.diy-uplight-element').addClass('d-none');
		removeElement($('.diy-uplight-qty-input'));
	});

	// Pinspots
	$('.diy-pinspots-qty-input').on('change', () => {
		updatePinspotsTotal();
		updateCheckoutTotals();
	});
	$('.pinspots-remove').on('click', () => {
		pinspotsRemove = true;
		updatePinspotsTotal();
		updateCheckoutTotals();
		$('.diy-pinspots-element').addClass('d-none');
		removeElement($('.diy-pinspots-qty-input'));
	});

	// Confetti
	if (typeof confettiProducts !== 'undefined' && $('.diy-confettis-product-input').length) {
		updateConfettiTotal();
		updateCheckoutTotals();
	}
	$('.diy-confettis-qty-input, .diy-confettis-product-input').on('change', () => {
		updateConfettiTotal();
		updateCheckoutTotals();
	});
	$('.diy-confetti-upgrade-input').on('change', () => {
		updateConfettiSubtotal();
		updateCheckoutTotals();
	});
	$('.confetti-remove').on('click', () => {
		confettiRemove = true;
		updateConfettiTotal();
		updateCheckoutTotals();
		$('.diy-confetti-element').addClass('d-none');
		removeElement($('.diy-confettis-qty-input'));
	});

	// Marquee
	$('.marquee-characters-btn').on('click', marqueeAddition);
	$('.marquee-letter-remove').on('click', marqueeRemoval);
	$('.marquee-submit-btn').on('click', function (e) {
		if ([`$${marqueeCost}.00`, '$0.00'].includes($('.diy-marquees-total').text())) {
			e.preventDefault();
			$('.marquee-characters-modal-alert').removeClass('d-none');
			$('.marquee-characters-modal-alert').html('Please add at least 2 Marquee Letters');
			$('#pickMarqueeLettersModal').modal('toggle');
		}
	});
	$('.diy-marquee-upgrade-input').on('change', () => {
		updateMarqueeSubtotal();
		updateCheckoutTotals();
	});
	$('.marquee-remove').on('click', () => {
		marqueeRemove = true;
		updateMarqueeTotal(0);
		$('.marquee-character-qty-input').each(function () {
			removeElement($(this));
		});
	});

	if (window.location.hash.includes('checked')) handleAvailabilityCheck();
});
