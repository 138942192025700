// Shipping Address
function handleShipToBilling() {
  if ($(this).is(':checked')) $('.shipping-address-form').addClass('d-none');
  else $('.shipping-address-form').removeClass('d-none');
};

const validateAddress = (address) => {
  $.ajax({
    url: validateAddressesEndpoint,
    type: 'GET',
    data: { address },
    success: function (data) {
      if (!data.success) {
        addressSuggestion = data.data;
        if (!addressSuggestion.address1) return;

        $('.checkout-ups-suggestion').html(`${addressSuggestion.address1}<br>${addressSuggestion.city} ${addressSuggestion.state} ${addressSuggestion.zip}`);
        $('.checkout-container-ups-suggestion').removeClass('d-none');
      }
    },
  });
};

function handleAddressValidation() {
  const kind = $('.ship-to-billing-checkbox').is(':checked') ? 'billing' : 'shipping';
  const address = {
    attention: $(`#reservation_${kind}_address_attributes_attention`).val(),
    address1: $(`#reservation_${kind}_address_attributes_address1`).val(),
    address2: $(`#reservation_${kind}_address_attributes_address2`).val(),
    city: $(`#reservation_${kind}_address_attributes_city`).val(),
    state: $(`#reservation_${kind}_address_attributes_state`).val(),
    zip: $(`#reservation_${kind}_address_attributes_zip`).val(),
  };

  $('.terms-attention').text(address.attention);
  $('.terms-address1').text(address.address1);
  $('.terms-address2').text(address.address2);
  $('.terms-location').text(`${address.city}, ${address.state}, ${address.zip}`);
  if (address.address1 && address.city && address.state && address.zip) validateAddress(address);
};

const acceptAddressValidation = () => {
  const kind = $('.ship-to-billing-checkbox').is(':checked') ? 'billing' : 'shipping';
  $(`#reservation_${kind}_address_attributes_attention`).val(addressSuggestion.attention);
  $(`#reservation_${kind}_address_attributes_address1`).val(addressSuggestion.address1);
  $(`#reservation_${kind}_address_attributes_address2`).val(addressSuggestion.address2);
  $(`#reservation_${kind}_address_attributes_city`).val(addressSuggestion.city);
  $(`#reservation_${kind}_address_attributes_state`).val(addressSuggestion.state);
  $(`#reservation_${kind}_address_attributes_zip`).val(addressSuggestion.zip);
  $('.checkout-container-ups-suggestion').addClass('d-none');
  $('.partial-order-update-input').trigger('blur');
}

$(function () {
  // Shipping Address
  $('.ship-to-billing-checkbox').on('change', handleShipToBilling);
  $('.checkout-address-input').on('change', handleAddressValidation);
  $('.checkout-dismiss-ups-suggestion').on('click', () => $('.checkout-container-ups-suggestion').addClass('d-none'));
  $('.checkout-accept-ups-suggestion').on('click', acceptAddressValidation);
});
