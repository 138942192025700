const handleStripe = () => {
  const style = {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };
  const elements = paymentStripe.elements();
  const card = elements.create('card', { style });
  card.mount('#card-element');
  const $form = $('#new_payment');

  card.addEventListener('change', function(event) {
    var displayError = document.getElementById('card-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  });

  $form.find('.submitPayment').on('click', function(event) {
    event.preventDefault();
    $form.find('.submitPayment').html('Validating <i class="fa fa-spinner fa-pulse"></i>').prop('disabled', true);
    paymentStripe.createToken(card).then(function(result) {
      if (result.error) {
        $form.find('.submitPayment').html('Try again').prop('disabled', false);
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
      } else {
        $form.find('.submitPayment').html('Processing <i class="fa fa-spinner fa-pulse"></i>').prop('disabled', true);
        stripeTokenHandler(result.token);
      }
    });
  });

  function stripeTokenHandler({ id: token }) {
    $("#payment_stripe_token").val(token);
    $form.submit();
  }
};

$(function() {
  if (typeof paymentStripe !== 'undefined') handleStripe();
});
