// Libraries
const debounce = require('lodash.debounce');

// Partial Updates
const partialOrderUpdate = debounce(() => {
	const data = {
		reservation: {
			user_attributes: {},
			billing_address_attributes: {},
		}
	};

	$('.rental-summary :input').each(function () {
		if (this.name.includes('upgrade')) data[this.name] = $(this).is(':checked');
		else if (this.name.startsWith('reservation')) data[this.name] = $(this).val();
	});

	const addUserAttribute = (attribute) => {
		const val = $(`#reservation_user_attributes_${attribute}`).val();
		if (val) data.reservation.user_attributes[attribute] = val;
	};

	const addAddressAttribute = (attribute) => {
		const val = $(`#reservation_billing_address_attributes_${attribute}`).val();
		if (val) data.reservation.billing_address_attributes[attribute] = val;
	};

	addUserAttribute('first_name');
	addUserAttribute('last_name');
	addUserAttribute('email');
	addUserAttribute('phone');
	addAddressAttribute('address1');
	addAddressAttribute('city');
	addAddressAttribute('state');
	addAddressAttribute('zip');

	$.ajax({
		url: reservationEndpoint,
		type: 'PUT',
		data,
	});
}, 1500);

// On Document Load
$(function () {
  // Partial Updates
  $('.partial-order-update-input').on('blur', partialOrderUpdate);
});
