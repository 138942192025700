let card;

const handleStripeSetup = () => {
  const style = {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4'
      }
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a'
    }
  };
  const stripeElements = stripe.elements();
  card = stripeElements.create('card', { hidePostalCode: true, style });
  card.mount('#stripe-card-element');

  card.addEventListener('change', function (event) {
    const displayError = document.getElementById('stripe-card-errors');
    if (event.error) {
      displayError.textContent = event.error.message;
    } else {
      displayError.textContent = '';
    }
  });
};

const handleStripeSubmit = () => {
  const btn = $('.submit-stripe-form-btn');
  btn.html('Validating <div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>').prop('disabled', true);
  stripe.createToken(card).then(function (result) {
    if (result.error) {
      btn.html('Try again').prop('disabled', false);
      const errorElement = document.getElementById('stripe-card-errors');
      errorElement.textContent = result.error.message;
    } else {
      btn.html('Processing <div class="spinner-border" role="status"><span class="sr-only">Loading...</span></div>').prop('disabled', true);
      stripeTokenHandler(result.token);
    }
  });
};

function stripeTokenHandler({ id: token }) {
  $("#reservation_payment_attributes_stripe_token").val(token);
  $('.stripe-form').attr('valid', true);
  $('.stripe-form').submit();
};

// On Document Load
$(function () {
  if (typeof stripe !== 'undefined') handleStripeSetup();

  $('.stripe-form').on('submit', function (e) {
    if (!this.reportValidity()) {
      e.preventDefault();
    } else if (!this.getAttribute('valid')) {
      e.preventDefault();
      handleStripeSubmit($(this));
    }
	});
});
