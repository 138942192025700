const validateElement = (element) => {
  if (element.val() === '') {
    element[0].setCustomValidity('Please fill out this field')
  } else {
    element[0].setCustomValidity('');
  }
};

const clearValidations = () => {
  $('#reservation_user_attributes_email')[0].setCustomValidity('');
  $('#reservation_user_attributes_phone')[0].setCustomValidity('');
  $('#reservation_shipping_address_attributes_attention')[0].setCustomValidity('');
  $('#reservation_shipping_address_attributes_address1')[0].setCustomValidity('');
  $('#reservation_shipping_address_attributes_city')[0].setCustomValidity('');
  $('#reservation_shipping_address_attributes_state')[0].setCustomValidity('');
  $('#reservation_shipping_address_attributes_zip')[0].setCustomValidity('');
  return true;
}

const verifyShippingAddress = () => {
  if ($('.ship-to-billing-checkbox').is(':checked')) return clearValidations();
  validateElement($('#reservation_shipping_address_attributes_attention'));
  validateElement($('#reservation_shipping_address_attributes_address1'));
  validateElement($('#reservation_shipping_address_attributes_city'));
  validateElement($('#reservation_shipping_address_attributes_state'));
  validateElement($('#reservation_shipping_address_attributes_zip'));
};

// On Document Load
$(function () {
  $('.submit-stripe-form-btn').on('click', function (event) {
    event.preventDefault();
    let validMarquees = true;
    if (store === 'diy' && parseFloat($('.diy-marquees-total').text().replace('$', '')) < marqueeCost * 2) {
      $('.marquee-characters-modal-alert').removeClass('d-none');
      $('.marquee-characters-modal-alert').html('Please add at least 2 Marquee Letters');
      $('#pickMarqueeLettersModal').modal('toggle');
      validMarquees = false;
    }
    verifyShippingAddress();
    $('.custom-email-validation').trigger('blur');
    $('.custom-phone-validation').trigger('blur');
    if (validMarquees) {
      $('.stripe-form').trigger('submit');
    }
  });
});
