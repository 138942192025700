const validateEmail = () => {
  const element = $(`#reservation_user_attributes_email`);
  const email = element.val();
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(email)) element[0].setCustomValidity('');
  else element[0].setCustomValidity('Please add a valid email');
};

const validatePhone = () => {
  const element = $(`#reservation_user_attributes_phone`);
  const phone = element.val();
  if (phone.replace(/[^0-9]/g, '').length === 10) element[0].setCustomValidity('');
  else element[0].setCustomValidity('Please add a valid phone');
};

// On Document Load
$(function () {
  // Contact Info
  $('.custom-email-validation').on('blur', validateEmail);
  $('.custom-phone-validation').on('blur', validatePhone);
});
