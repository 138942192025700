const sq_feet_to_lights = (sq_feet, target) => {
  x = Math.round(sq_feet / 100);
  if (x <= 7) lights = x + 1;
  else if (7 <= x && x <= 14) lights = x + 2;
  else if (14 <= x && x <= 21) lights = x + 3;
  else lights = x;

  mid = lights;

  $(`.${target}-result`).html(`<p><b>Recommended Uplight Quantity:</b> ${mid}</p>`);
  $(`.${target}-buttons`).html('<input type="submit" name="commit" value="Recalculate" class="btn btn-light" data-disable-with="Recalculate"><a class="btn btn-primary ml-2" href="/uplighting-rental">Book Now</a>')
}

$(function() {
  $('.area-calculator-form').on('submit', function(event) {
    event.preventDefault();
    const width = parseInt($(this).find('.area-width').val()) || 0;
    const length = parseInt($(this).find('.area-length').val()) || 0;
    sq_feet_to_lights(width * length, 'area');
  });

  $('.square-calculator-form').on('submit', function(event) {
    event.preventDefault();
    const sqr = parseInt($(this).find('.square-length').val()) || 0;
    sq_feet_to_lights(sqr, 'square');
  });
});
