$(function () {
  $('.gallery').each(function () {
    var galleryId = $(this).attr('id');
    $('#' + galleryId + ' .owl-carousel').owlCarousel({
      loop: true,
      margin: 10,
      nav: true,
      center: true,
      lazyLoad: true,
      lazyLoadEager: 1,
      navContainer: '#' + galleryId + ' .gallery-nav',
      dotsContainer: '#' + galleryId + ' .gallery-dot',
      responsive: {
        0: {
          items: 1,
          stagePadding: 40,
        },
        600: {
          items: 2,
          stagePadding: 100,
        },
        1064: {
          items: 3,
          stagePadding: 100,
        }
      }
    })

    $('#' + galleryId + ' .owl-carousel').on('changed.owl.carousel', function (event) {
      var index = event.item.index;
      var item = $("#" + galleryId + ' .owl-carousel .owl-stage').children().eq(index).children('.item');
      showGalleryItemDescription(galleryId, item);
    });

    showActiveGalleryItemDescription(galleryId);
  });

  function showActiveGalleryItemDescription(galleryId) {
    $("#" + galleryId + ' .owl-carousel .owl-item.active.center .item').each(function () {
      var center = $(this);
      showGalleryItemDescription(galleryId, center)
    });
  }

  function showGalleryItemDescription(galleryId, item) {
    $("#" + galleryId + ' .gallery-description').hide();
    $('#' + item.attr('data-description-id')).show();
  }

  function isOnScreen(elem) {
    // if the element doesn't exist, abort
    if (elem.length == 0) {
      return;
    }
    var $window = jQuery(window)
    var viewport_top = $window.scrollTop()
    var viewport_height = $window.height()
    var viewport_bottom = viewport_top + viewport_height
    var $elem = jQuery(elem)
    var top = $elem.offset().top
    var height = $elem.height()
    var bottom = top + height

    return (top >= viewport_top && top < viewport_bottom) ||
      (bottom > viewport_top && bottom <= viewport_bottom) ||
      (height > viewport_height && top <= viewport_top && bottom >= viewport_bottom)
  }

  $('.owl-carousel').on('changed.owl.carousel', function (e) {
    if (isOnScreen($(this)) && $(this).is(':visible')) {
      $(this).find('img').each(function () {
        const dataOriginal = $(this).attr('data-original');
        $(this).attr('src', dataOriginal);
      });
    }
  });
});
