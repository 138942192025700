import './bootstrap-custom';
import './jquery.lazyload';
import './jquery-ui-custom';
import './gallery';
import './wistia';
import 'owl.carousel';

// Initial integrations setup
$(function () {
  const cutDate = new Date();
  cutDate.setDate(cutDate.getDate() + 9);
  $(".date-picker").datepicker({
    showOtherMonths: true,
    selectOtherMonths: true,
    navigationAsDateFormat: true,
    dateFormat: 'mm/dd/yy',
    minDate: typeof controlCutOffDate !== 'undefined' ? cutDate : new Date(),
    defaultDate: $("input[name='reservation[event_date]']").val() ? $("input[name='reservation[event_date]']").val() : new Date(),
    nextText: 'M >',
    prevText: '< M',
    monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    onSelect: function (dateText) {
      $("input[name='reservation[event_date]']").val(dateText);
      $(".diy-event-date-input").trigger("change");
    }
  }).attr('readonly', 'readonly');

  $(".simple-datepicker").datepicker({
    dateFormat: 'yy-mm-dd',
  });

  $('.testimonials-section .owl-carousel').owlCarousel({
    loop: true,
    margin: 10,
    nav: true,
    lazyLoad: true,
    lazyLoadEager: 1,
    responsive: {
      0: { items: 1, stagePadding: 50 },
      600: { items: 2 },
      1000: { items: 2 }
    }
  });

  $('.product .owl-carousel').owlCarousel({
    loop: true,
    margin: 10,
    nav: false,
    items: 1,
    center: true,
    dotsData: true,
    lazyLoad: true,
    lazyLoadEager: 1,
    dotsContainer: '.product-dot',
  });

  $('.product .owl-dot').on('click', function () {
    $('.product .owl-carousel').trigger('to.owl.carousel', [$(this).index(), 300]);
    $('.product .owl-dot').removeClass('active');
    $(this).addClass('active');
  })

  $('.select-nav').on('change', function (e) {
    $('.nav-tabs li a').eq($(this).val()).tab('show');
  });

  $('.lazy-modal').on("show.bs.modal", function (e) {
    $(this).find('img').each(function () {
      const dataOriginal = $(this).attr('data-original');
      $(this).attr('src', dataOriginal);
    });
  });

  $("img").lazyload();
  $('.toast').toast({ delay: 10000, animation: false });
  $('.toast').toast('show');
});
